<template src="./printtransaction.html"></template>
<style scoped src ="./printtransaction.css">

</style>
<script>
import Loading from 'vue-loading-overlay'
import transactionService from '@/services/TransactionService.vue'
import utils from '@/utils/utils.vue'
import modal from '@/components/modal/notification/modal'
import moment from 'moment'

export default {
    components: {
        Loading, modal
    },
    data() {
        return {
            requestId: '',
            notes: '',
            resData: {},
            currentPage: 1,
            perPage: 8,
            rows: 0,
            loaderVisible: false,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            fields: [
                { key: 'CreateDateUtc', label: 'Created Date' },
                { key: 'CreatedBy', label: 'Created by' },
                { key: 'Note', label: 'Note' }
            ]
        }
    },
    methods: {
        async parentTransactionDetails() {
            let transactionSummarysResponse = await transactionService.methods.GetTransactionSummary({ ReferenceNumber: this.$route.params.ParentReferenceNumber })
            let transactionSummary = transactionSummarysResponse.csTransactionSummaryResponse.TransactionSummary.csTransactionSummaryItem
            if (transactionSummary) {
                let transaction = Array.isArray(transactionSummary) ? transactionSummary[0] : transactionSummary
                let transactionDetailsResponse = await transactionService.methods.GetTransactionDeatils({
                    CustomerEmail: transaction.SenderEmail._text,
                    TransactionId: transaction.TransactionId._text
                })
                let transactionDetails = await this.responseMapping(transactionDetailsResponse.csTransactionDetailsResponse.TransactionData)[0]
                if (['From Bank Account', 'E-Transfer', 'EFT'].includes(transactionDetails.TransactionType))
                    await this.mapMoneyTransferInfo(transactionDetails)
            }
        },
        async mapMoneyTransferInfo(transactionData) {
            if(transactionData.ClientStatus) {
                switch (transactionData.ClientStatus) {
                    case '1':
                        this.resData.ClientStatus = 'Initiated'
                        break
                    case '2':
                        this.resData.ClientStatus = 'Waiting for Interac'
                        break
                    case '3':
                        this.resData.ClientStatus = 'Funded'
                        break
                    case '4':
                        this.resData.ClientStatus = 'Not Funded'
                        break
                    case '5':
                        this.resData.ClientStatus = 'Cancelled'
                        break
                }
            }
            if (transactionData.RemittanceData.MoneyTransferRequestSavedData && Object.keys(transactionData.RemittanceData.MoneyTransferRequestSavedData).length > 0) {
                transactionData.MoneyTransferData = Object.keys(transactionData.RemittanceData.MoneyTransferRequestSavedData)
                    .reduce((acc, key) => {
                        if (key === '_attributes') { return acc }
                        if (typeof (transactionData.RemittanceData.MoneyTransferRequestSavedData[key]) === 'object' && transactionData.RemittanceData.MoneyTransferRequestSavedData[key]) {
                            return acc += Object.keys(transactionData.RemittanceData.MoneyTransferRequestSavedData[key]).reduce((childacc, childkey) => {
                                if (childkey === '_attributes' && Object.keys(transactionData.RemittanceData.MoneyTransferRequestSavedData[key]).length === 1) { return childacc += 'NULL <br>' }
                                else if (childkey === '_attributes' && Object.keys(transactionData.RemittanceData.MoneyTransferRequestSavedData[key]).length > 1) { return childacc }
                                return childacc += childkey + '=' + transactionData.RemittanceData.MoneyTransferRequestSavedData[key][childkey] + '<br>'
                            }, key.toUpperCase() + '<br>')
                        } else {
                            return acc += key + '=' + transactionData.RemittanceData.MoneyTransferRequestSavedData[key] + '<br>'
                        }
                    }, 'MoneyTransferRequestSavedData<br>')
                let moneyTransferElement = document.getElementById('MoneyTransferInfo')
                moneyTransferElement ? moneyTransferElement.innerHTML = transactionData.MoneyTransferData : null
            }
            //mapping items to fields
            this.resData.RConsumerEmail = transactionData.RemittanceData?.MoneyTransferRequestSavedData?.ConsumerEmail || null
            this.resData.RFee = transactionData.RemittanceData?.MoneyTransferRequestSavedData?.Fee || null
            this.resData.RCustomerIsReliable = transactionData.RemittanceData?.MoneyTransferRequestSavedData?.MrmsCallData?.CustomerIsReliable || null
            this.resData.RLastSuccessfulTransactions = transactionData.RemittanceData?.MoneyTransferRequestSavedData?.MrmsCallData?.LastSuccessfulTransactions || null
            this.resData.RSessionId = transactionData.RemittanceData?.MoneyTransferRequestSavedData?.MrmsCallData?.SessionId || null
            this.resData.RTransactionSource = transactionData.RemittanceData?.MoneyTransferRequestSavedData?.MrmsCallData?.TransactionSource || null
            this.resData.ROriginCurrency = transactionData.RemittanceData?.MoneyTransferRequestSavedData?.OriginCurrenty || null
            this.resData.RRemittanceParentReferenceNumber = transactionData.RemittanceData?.MoneyTransferRequestSavedData?.ParentReferenceNumber || null
            this.resData.RPurpose = transactionData.RemittanceData?.MoneyTransferRequestSavedData?.Purpose || null
            this.resData.RRecipientId = transactionData.RemittanceData?.MoneyTransferRequestSavedData?.RecipientId || null
            this.resData.RRequestedAmount = transactionData.RemittanceData?.MoneyTransferRequestSavedData?.RequestedAmount || null
            if (transactionData.RemittanceData?.MoneyTransferRequestSavedData?.PromoCodeData && Object.keys(transactionData.RemittanceData.MoneyTransferRequestSavedData.PromoCodeData).length > 0) {
                this.resData.PromoCode = transactionData.RemittanceData?.MoneyTransferRequestSavedData?.PromoCodeData?.Code || null
                this.resData.Issue = transactionData.RemittanceData?.MoneyTransferRequestSavedData?.PromoCodeData?.Issue || null
                this.resData.RemittanceGateway = transactionData.RemittanceData?.MoneyTransferRequestSavedData?.PromoCodeData?.RemittanceGateway || null
                this.resData.Amount = transactionData.RemittanceData?.MoneyTransferRequestSavedData?.PromoCodeData?.Amount || null
            }
            this.resData.MoneyTransferData = transactionData.MoneyTransferData || null
            this.resData.ClientIP = transactionData.ClientIP || null
        },
        mapTransactionData(transactionData) {
            this.resData = transactionData
            this.resData.TransactionStatusLastUpdatedTime = transactionData.TransactionStatusLastUpdatedTime ? moment.utc(transactionData.TransactionStatusLastUpdatedTime, 'MM/DD/YYYY HH:mm:ss A').local().format("MM/DD/YYYY hh:mm:ss A") : null
            this.resData.DateTime = transactionData.DateTime ? utils.methods.UTCtoLocal(transactionData.DateTime) : null
            if (transactionData.TransactionType === 'Money Transfer') {
                this.resData.FirstName = transactionData.Recipient?.FirstName || null
                this.resData.LastName = transactionData.Recipient?.LastName || null
                this.resData.DestinationCurrency = transactionData?.DestCurrency || null
                this.resData.AmountReceived = transactionData.DestAmount || null
                // this.resData.ExchangeRate = transactionData.ExchangeRate ? (1 / parseFloat(transactionData.ExchangeRate)).toFixed(3) : null
            }
        },
        async handleAddNotesEvent() {
            try {
                this.loaderVisible = true
                await transactionService.methods.AddTransactionNotes(this.notes, this.resData.ReferenceNumber)
                    .then(async () => {
                        this.notes = ''
                        await transactionService.methods.GetTransactionDeatils({ CustomerEmail: this.$route.params.CustomerEmail, TransactionId: this.$route.params.TransactionId })
                            .then(res => this.updatesNotesTable(res.csTransactionDetailsResponse.TransactionNotes.csTransactionNote))
                            .catch(() => { })
                        this.loaderVisible = false
                        this.showAlert(1, 'Notes Added Successfully')
                    })
                    .catch(err => {
                        this.loaderVisible = false
                        this.alertObject = err
                        this.$bvModal.show(this.$refs.childModal.id)
                    })
            } catch (error) {
                this.alertObject = error
                this.$bvModal.show(this.$refs.childModal.id)
            }
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        },
        responseMapping(data) {
            if (data) {
                data = Array.isArray(data) ? data : [data]
                data.forEach(obj => {
                    Object.keys(obj).map(key => {
                        if (key === 'Recipient') {
                            Object.keys(obj[key]).map(recipientkey => {
                                if (recipientkey === 'CustomProperties') {
                                    let customprop = obj[key][recipientkey].NameValuePair
                                    if (customprop) {
                                        customprop = Array.isArray(customprop) ? customprop : [customprop]
                                        customprop.forEach(arr => Object.keys(arr).map(arrKey => arr[arrKey] = arr[arrKey]._text ? arr[arrKey]._text : null))
                                    }
                                } else {
                                    obj[key][recipientkey] = obj[key][recipientkey]._text ? obj[key][recipientkey]._text : null
                                }
                            })
                        } else if (key === 'RemittanceData') {
                            if (obj[key]._text) {
                                if ((obj[key]._text).indexOf('MoneyTransferRequestSavedData') >=  0) {
                                    let xmlData = (utils.methods.getParseObjectFromXml(obj[key]._text)).MoneyTransferRequestSavedData
                                    Object.keys(xmlData).map(key => {
                                        if (['PromoCodeData', 'MrmsCallData'].includes(key)) {
                                            if (xmlData[key] && Object.keys(xmlData[key]).length > 0) {
                                                Object.keys(xmlData[key]).map(childKey => xmlData[key][childKey] = xmlData[key][childKey]._text ? xmlData[key][childKey]._text : null)
                                            }
                                        } else {
                                            xmlData[key] = xmlData[key]._text ? xmlData[key]._text : null
                                        }
                                    })
                                    obj[key] = { 'MoneyTransferRequestSavedData': xmlData }
                                }
                            }
                        } else {
                            obj[key] = obj[key]._text ? obj[key]._text : null
                        }
                    })
                })
            } else {
                data = []
            }
            return data
        },
        goBack() {
            this.$router.push({
                name: this.$route.params.fromName || 'ViewTransactions',
                params: {
                    filters: this.$route.params
                }
            })
        },
        printTransation() { 
            this.perPage = this.rows
            this.$nextTick(()=> {
                this.$htmlToPaper('printArea') 
            })
        },
        updatesNotesTable(transactionNotes) {
            if (transactionNotes) {
                transactionNotes = Array.isArray(transactionNotes) ? transactionNotes : [transactionNotes]
                transactionNotes.forEach(note => {
                    Object.keys(note).map(key => {
                        note[key] = note[key]._text ? note[key]._text : null
                        if (key === 'CreateDateUtc')
                            note[key] = utils.methods.UTCtoLocal(moment(note.CreateDateUtc).format('MM/DD/YYYY HH:mm:ss'))
                    })
                })
            } else {
                transactionNotes = []
            }
            this.resData.notes = transactionNotes
            this.rows = this.resData.notes?.length
        }
    },
    computed: {
        fromRecord () {
            return ((this.currentPage-1) * this.perPage) + 1
        },
        toRecord () {
            return this.currentPage * this.perPage > this.rows ? this.rows : this.currentPage * this.perPage
        }
    },
    async mounted() {
        this.requestId = utils.methods.getRandomNumber()
        this.loaderVisible = true
        await transactionService.methods.GetTransactionDeatils({ CustomerEmail: this.$route.params.CustomerEmail, TransactionId: this.$route.params.TransactionId })
            .then(async res => {
                let transactionData = await this.responseMapping(res.csTransactionDetailsResponse.TransactionData)[0]
                transactionData.ExchangeRate = transactionData.ExchangeRate ? (1 / parseFloat(transactionData.ExchangeRate)).toFixed(3) : null
                this.mapTransactionData(transactionData)
                this.updatesNotesTable(res.csTransactionDetailsResponse.TransactionNotes.csTransactionNote)
                if (['From Bank Account', 'E-Transfer', 'EFT'].includes(transactionData.TransactionType)) {
                    transactionData.ExchangeRate = '0.00'
                    await this.mapMoneyTransferInfo(transactionData)
                } else {
                    await this.parentTransactionDetails()
                }
            })
            .catch(err => {
                this.alertObject = err
                this.$bvModal.show(this.$refs.childModal.id)
            })
        setTimeout(function () {
            let elements = document.getElementsByClassName('form-control')
            for (let item of elements) {
                if (!(item.innerHTML && item.innerHTML.length > 0)) {
                    item.closest('.row').style.display = 'none'
                }
            }
        }, 1500)
        this.loaderVisible = false
    }
}
</script>